<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
 

  export default {
    name: 'App',
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    data: function() {
      return  {
        
      }
    },
    methods: {
      getLocation: function() {

       const options = {
          method: 'GET',
          url: '/json',
          };
          this.axios(options)
              .then((response) => {
                this.init.location = response.data;

                // response.data.country_code = "KR";
                if(response.data.country_code == 'KR') {
                  localStorage.setItem("lang","kr");
                } else {
                  localStorage.setItem("lang","en");
                }
              })
              .catch((error) => {
                console.log(error)
               
              });
      }
   },

    beforeMount(){
     
      
    },

    mounted(){
     this.getLocation();
    },

    watch: {

    }
  }
</script>


<style lang="scss">
  @font-face {
    font-family: 'SSN-Medium';
    src: url(./assets/fonts/SoinSansNeue-Medium.otf);
    font-style: normal;
  }

  @font-face {
    font-family: 'SSN-Bold';
    src: url(./assets/fonts/SoinSansNeue-Bold.otf);
    font-style: normal;
  }

  @font-face {
    font-family: 'SSN-Light';
    src: url(./assets/fonts/SoinSansNeue-Light.otf);
    font-style: normal;
  }

  @font-face {
    font-family: 'SSN-Roman';
    src: url(./assets/fonts/SoinSansNeue-Roman.otf);
    font-style: normal;
  }

#captchaImg {
    background-color:transparent;
    width:160px;
    height:60px;
    padding:5px;
    object-fit:contain;
}
#captcha {
    margin-top:10px;
    margin-bottom:20px;
    width: 75%;
   padding: 10px;
   border-radius:5px;
}
#captcha input {
  height:61px;
  border-left: none;
  border-top: none;
  border-right: none;
    border-bottom: 2px black solid;
    border-radius:0px;
    font-size:30px;
    padding:5px;
    background:none;
    color:#000;
}

  .private-dropdown button { 
  background-color: #FFF;
  color: #212529;
  border: none;
}

    #tsparticles {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}


  #app {
    font-family: 'SSN-Medium';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  html {
    font-size: 100%;
    box-sizing: border-box; 

  }

  *, *::before, *::after {
    box-sizing: inherit;
  }


  body {
    margin: 0;
    padding: 0;
    line-height: 1.3;
    font-family: 'Montserrat-Regular', sans-serif !important; 
    font-size: 1.125rem;
     background: #0D0F15;
     background-color: #0D0F15 !important;

  }

 
  .modal-header, #modal-header {
    background: linear-gradient(to right, #110737, #150754) !important;
    color: #fff !important;
    fill:#fff !important;
  }

  .v-icon {
    color:#fff;
  }
 

  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }

  .btn-main {
    background-color: #0e649e !important;
    color: #FFF !important;
    width: 100%;
  }

  .btn-cancel {
    border: #0e649e 2px solid !important;
    background:none;
    color: #FFF !important;
    width: 100%;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000; 
    bottom: 0.5em;
    width: 100%;
  }

  .alert-danger {
    color: #005837;
    background-color: #c2f7cb;
    border-color: #c2f7cb;
  }

   .help-block {
    font-size:1em;
    padding: 0;
    margin: 0;
    color: #a94442; 
  }

  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {


    .hide-for-desktop {
        display: none;
    }

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
     #app {
      
      padding: 1em;
      font-size: 75%;

    }
  }
  /*med*/
  @media(max-width: 63.9375em) {
    .hide-for-mobile {
        display: none;
    }
     #app {
       
      font-size: 85%;

    }
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(min-width: 768px) {
     
  }
</style>
