import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../components/Signup.vue')
  },
  {
    path: '/setup-profile',
    name: 'SetupProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setup-profile" */ '../components/SetupProfile.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../components/Dashboard.vue')
  },
  {
    path: '/view/private',
    name: 'ViewLink',
    component: () => import('../components/ViewLink.vue')
  },

  {
    path: '/view/:entityId/:hash',
    name: 'ViewLink',
    component: () => import('../components/ViewLink.vue')
  },
 
  {
    path: '/data-assets/:key',
    props: true,
    name: 'DataAssets',
    component: () => import('../components/DataAssets.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../components/Profile.vue')
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () => import('../components/Requests.vue')
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
