import { renderFile } from 'ejs';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);



const messages = {
    'en': {
    	login: {
    		label: 'LOGIN',
			forgotPinLabel: "FORGOT PIN",
    		mobileLabel: 'Mobile No.',
    		emailAddressLabel: 'Email Address',
    		pin: 'PIN',
    		forgotPin: 'Forgot PIN',
    		showPin: 'Show',
    		hidePin: 'Hide',
    		signUp: 'Sign Up',
			submit: 'SUBMIT',
			backToLogin: 'Back to login'
    	},

		signup: {
    		label: 'SIGNUP',
    		mobileLabel: 'Mobile No.',
    		emailAddressLabel: 'Email Address',
    		pin: 'PIN',
    		showPin: 'Show',
    		hidePin: 'Hide',
    		login: 'Login',

			authentication: 'AUTHENTICATION',
			resend2fa1: "Didn't receive 2FA?",
			resend2fa2: 'Try Again',
			authText: 'Authentication code has been sent to your mobile/email. Kindly enter below',
			cancel: 'CANCEL'
    	},

		setProfile: {
			label: 'SET UP PROFILE',
			firstName: 'First Name',
			lastName: 'Last Name'
		},

		setWallet: {
			label: 'SET UP WALLET',
			import: 'Import Existing Wallet',
			create: 'Create New Wallet',

			importLabel: 'IMPORT EXISTING WALLET',
			yourPrivateKey: 'Your Private Key',
			importButton: 'Import',

			generateWallet: 'Generating your wallet',
			generateSuccess1: 'Your wallet is successfully created.',
			generateSuccess2: 'You will be redirected to the Dashboard in a few seconds.',
		},

		dashboard: {
			history: 'History',
			changePin: 'Change PIN',
			twofaVerification: '2FA Verification',
			getPrivateKey: 'Get Private Key',
			view: 'View',
			import: 'Import',
			generate: 'Generate',

			rewardNode: "Be a RewardNode",
			unstake: "Unstake",

			logout: 'Logout',

			mainWallet: 'MAIN WALLET',
			subWallet: 'SUBWALLET',
			myBlx: 'MY CEZA',
			myTokens: 'MY TOKENS',
			myNfts: 'MY NFTS',
			tokensReceived: 'TOKENS RECEIVED',
			nftsReceived: 'NFTS RECEIVED',

			blxWallet: 'CEZA WALLET',
			bloxStake: 'BLOX STAKE',
			bloxdaoVote: 'BLOX DAO VOTE',
			bloxBank: 'BLOX BANK',

			walletBalance: 'Wallet Balance',
			comingSoon: 'Coming Soon',
			stakeVotingPower: 'Stake Voting Power',

			recentTransactions: 'RECENT TRANSACTIONS',
			timestamp: 'TIMESTAMP',
			hash: 'HASH',
			input: 'INPUT',
			output: 'OUTPUT',
			amount: 'AMOUNT',
			symbol: 'SYMBOL',
			confirmations: 'CONFIRMATIONS',
			status: 'STATUS',

			completed: 'COMPLETED',
			pending: 'PENDING',

			// logout
			logoutLabel: 'ARE YOU SURE?',
			ok: 'OK',
			cancel: 'CANCEL',

			// change pin
			changePinLabel: 'CHANGE PIN',
			currentPin: 'Current PIN',
			newPin: 'New PIN',
			confirmNewPin: 'Confirm New PIN',
			showCurrentPin: 'SHOW',
    		hideCurrentPin: 'HIDE',
			showNew1Pin: 'SHOW',
    		hideNew1Pin: 'HIDE',
			showNew2Pin: 'SHOW',
    		hideNew2Pin: 'HIDE',

			// 2fa verification
			twofaVerificationLabel: '2FA VERIFICATION',
			mobileNumber: 'Mobile Number',
			email: 'Email',
			googleAuth: 'Google Authenticator',
			enterPin: 'Enter PIN to Confirm',
			confirm: 'Confirm',

			// view private key
			viewPrivateKeyLabel: 'VIEW PRIVATE KEY',
			enterYourPin: 'Enter your PIN',
			submit: 'SUBMIT',
			enterReceivedPin: 'Enter the one-time PIN you received:',
			resend2fa1: "Didn't receive 2FA?",
			resend2fa2: 'Try Again',
			
			// get private key
			getPrivateKeyLabel: 'GET YOUR PRIVATE KEY',
			walletSeed: 'WALLET SEED',
			pleaseSave: 'Please save these 5 codes with 7 Alpha numeric characters on paper (order is important). This seed will allow you to recover your wallet in case of computer failure.',
			warning: 'WARNING:',
			warning1: '- Never disclose your seed.',
			warning2: '- Never type it on a website.',
			warning3: '- Do not store it electronically.',
			warning4: '- You can only access this once.',
			yourWalletGeneration: 'Your wallet generation seed are',

			seed1: 'Seed 1',
			seed2: 'Seed 2',
			seed3: 'Seed 3',
			seed4: 'Seed 4',
			seed5: 'Seed 5',

			verify: 'Verify',
			copy: 'COPY',
			close: 'Close',

			// import private key
			importPrivateKeyLabel: 'IMPORT PRIVATE KEY',
			importWarning1: 'IMPORTANT',
			importWarning2: ' - Please be sure to have your Private key before migrating a new one.',
			enterPrivateKey: 'Enter your Private Key',
			importSuccessful: 'Import Successful.',

			// generate private key
			generateWalletAddressLabel: 'GENERATE WALLET ADDRESS',
			generating: "Generating your wallet...",
			generateSuccess: "You have successfully generated a new wallet address.",

			confirmLabel: "CONFIRM",
			pleaseSaveConfirm: "PLEASE SAVE YOUR PRIVATE KEY AND YOUR 5 SEED CODES. YOU LOSE THIS KEY, YOU LOSE THIS WALLET!",

			// asset cards
			viewDetails: "VIEW DETAILS",
			send: "SEND",

			next: "NEXT"
		},

		blxDashboard: {
			back: 'BACK',
			logout: 'LOGOUT',

			walletBalance: 'Wallet Balance',

			coldStorage: 'Cold Storage',
			send: 'Send',
			receive: 'Receive',
			history: 'History',
			verumCoin: 'Verum Coin',
			cryptoWallet: "Crypto Wallet",

			recentTransactions: 'RECENT TRANSACTIONS',
			timestamp: 'TIMESTAMP',
			hash: 'HASH',
			input: 'INPUT',
			output: 'OUTPUT',
			amount: 'AMOUNT',
			symbol: 'SYMBOL',
			confirmations: 'CONFIRMATIONS',
			status: 'STATUS',

			completed: 'COMPLETED',
			pending: 'PENDING',

			// cold storage 
			coldStorageLabel: 'EXPORT/IMPORT CEZA',
			balance: 'BALANCE',
			export: 'EXPORT',
			import: 'IMPORT',
			fileSecretKey: 'File Secret Key',
			file: 'File',
			filePlaceholder: 'Choose a file or drop it here...',
			dropPlaceholder: 'Drop file here...',
			browse: 'Browse',
			amount: 'Amount',
			successfulExport: 'Exported successfully.',
			mustRead: 'IMPORTANT: MUST READ!',
			importantMsg1: 'The File Secret Keys provided are dynamically assigned.',
			importantMsg2: 'Please save immediately and securely.',
			importantMsg3: 'DO NOT LOSE!',
			alreadySaved: 'Yes, I already save my file secret key',

			// send
			sendLabel: 'SEND CEZA',
			recipientAddress: 'Recipient Address',
			send: 'Send',
			sendSuccess1: 'You successfully sent',
			sendSuccess2: 'CEZA to',

			// deposit
			depositLabel: 'DEPOSIT CEZA',
			depositMsg: 'Scan the QR Code or Send the coins to this CEZA address',
			copied: 'Copied!',

			// verum coin
			verumCoinLabel: 'REDEEM CEZA FROM VERUM COIN',
			serialNo: 'Serial No.',
			redeem: 'Redeem',

			resend2fa1: "Didn't receive 2FA?",
			resend2fa2: 'Try Again',

			chooseAsset: 'Choose Asset',
		},

		history: {
			stake: 'STAKE',
			history: 'HISTORY',
			stakeHistory: 'STAKE HISTORY',
			go: 'GO',
			chooseAsset: 'Choose Asset',

			refId: 'REF ID',
			bloxStake: 'BLOX STAKE',
			btcAmount: 'BTC AMOUNT'
		},

		createAsset: {
			createTokenLabel: "CREATE TOKEN ASSET",
			createNftLabel: "CREATE NFT ASSET",
			sendBlx: "SEND CEZA",
			send: "Send",
			cancel: "Cancel",

			important: "IMPORTANT",
			importantMsg1: "This is your Asset Agreement Encryption Key (AKA Smart Contract Hash ID)",
			importantMsg2: "Please copy and secure your ASSET KEY below immediately",

			tapToCopy: "Tap to Copy",
			keyIsCopied: "Asset key is copied",
			done: "Done",

			uploadConfig: "Upload config file",
			or: "or",
			assetName: "Asset Name",
			symbol: "Symbol/Ticker",
			supply: "Circulating Supply",
			value: "Value in CEZA",
			description: "Description",
			warning: "IMPORTANT: SECURE AND SAVE IMMEDIATELY TO PREVENT UNAUTHORIZED ACCESS! DO NOT LOSE AND DO NOT SHARE YOUR PRIVATE KEYS!",
			submit: "Submit",

			assetNamePlaceholder: 'Token Asset Name',
			symbolPlaceholder: '6 characters',
			supplyPlaceholder: 'Maximum limit 10B',
			descPlaceholder: '150 characters',

			icon: "Icon",
			tokenGuidelines: "Upload image. Max size: 2MB. (.jpg, .png)",
			nftGuidelines: "Upload image. Max size: 10MB. (.jpg, .png, .mp3, .mp4)",

			enterAssetKeyLabel: "Enter your Asset Key to activate your smart contract",

			nowActivated: "Your smart contract is now activated.",
			done: "Done",

			// upload icon
			uploadIconLabel: 'Upload Icon',
			uploadNftLabel: 'Upload NFT',
			maximum: "Maximum of 10MB",
			maximumToken: "Maximum of 2MB",

			// upload config
			configLabel: "Upload Config File",
			configFile: "Config File",
			filePlaceholder: 'Choose a file or drop it here...',
			dropPlaceholder: 'Drop file here...',
			getSample: "Get a sample here",
			upload: "UPLOAD"
		},

		assetDashboard: {
			back: 'BACK',
			logout: 'LOGOUT',

			balance: "BALANCE",
			assetName: "ASSET NAME",
			assetSymbol: "ASSET SYMBOL",
			totalSupply: "TOTAL SUPPLY",
			walletAddress: "WALLET ADDRESS",
			description: "DESCRIPTION",

			recentTransactions: 'RECENT TRANSACTIONS',
			timestamp: 'TIMESTAMP',
			hash: 'HASH',
			input: 'INPUT',
			output: 'OUTPUT',
			amount: 'AMOUNT',
			symbol: 'SYMBOL',
			confirmations: 'CONFIRMATIONS',
			status: 'STATUS',

			// send subasset
			sendLabel: 'SEND',
			yourPrivateKey: 'Your Private Key'
		},

		cryptoWallet: {
			chooseCryptoLabel: "CHOOSE CRYPTOCURRENCY",
			deposit: "DEPOSIT",
			exchange: "EXCHANGE",
			withdrawLabel: "WITHDRAW",
			withdraw: "Withdraw",

			receive: "RECEIVE",
			balance: "BALANCE",
			scanQr1: "Scan the QR Code or Send the coins to this",
			scanQr2: "address:",
			copy: "COPY",
			copied: "COPIED",

			next: "NEXT",
			tokenOnly1: "This address is for",
			tokenOnly2: "only!",
			tokenOnlyDesc: "Don't send other currencies to it! If you send any other crypto, you won't be able to receive or return it.",
			amount: "Amount",
			hashId: "Transaction Hash ID:",
			submit: "SUBMIT",
			cancel: "CANCEL",
			depositSuccess: "Your transaction is in process. Your account will be credited once your deposit is confirmed.",
			close: "CLOSE",

			enterAmountOf: "Enter amount of",
			amountOf: "Amount of",
			exchangeBtn: "Exchange",
			exchangeSuccessful: "Transaction Successful.",
			tokens: "Tokens:",
			amountIn: "Amount in",
			price: "Price:",
			per: "per",
			done: "DONE",
		},

		chat: {
			hi: 'Hi,',
			instructions: 'Send a message by pasting their wallet address below.',
			enterWalletAddress: 'Enter wallet address',
			startChat: 'Start Chat',
			contactsList: 'Contacts List',
			newMessage: 'New Message',
			noContact: 'No Contact',
			exitChat: 'Exit Chat',
			writeMsg: 'Write a message'
		},
		
		rewardNode: {
			label: "BE A REWARD NODE",
			balance: "BALANCE",
			proof: "PROOF OF STAKE REQUIRED",
			ok: "OK",
			cancel: "CANCEL",
			important: "IMPORTANT",
			keySubtext1: "This is your Asset Agreement Encryption Key (AKA Smart Contract Hash ID)",
			keySubtext2: "Please copy and secure your ASSET KEY below immediately",
			tapToCopy: "Tap to Copy",
			isCopied: "Asset key is copied",
			download: "Download",
			instructions1: "To run the downloaded .JAR file, you must have downloaded Java 8, then execute this command in your terminal:",
			done: "Done",

			//unstake
			unstakeLabel: "UNSTAKE",
			assetKey: "Asset Key",
			submit: "SUBMIT",
			unstakeSuccessful: "Unstaked successfully",
			done: "DONE"
		}
    },
	//
	//
	//
	//
	//
	//
	//
	//
    'kr': { 
    	login: {
    		label: '로그인',
			forgotPinLabel: "핀 분실",
    		mobileLabel: '모바일 번호',
    		emailAddressLabel: '이메일 주소',
    		pin: '핀',
    		forgotPin: '핀 분실',
    		showPin: '보여 주다',
    		hidePin: '숨바꼭질',
    		signUp: '등록하다',
			submit: '제출하다',
			backToLogin: '로그인으로 돌아가기'
    	},

		signup: {
    		label: '등록하다',
    		mobileLabel: '모바일 번호',
    		emailAddressLabel: '이메일 주소',
    		pin: '핀',
    		showPin: '보여 주다',
    		hidePin: '숨바꼭질',
    		login: '로그인',

			authentication: '인증',
			resend2fa1: "2FA를 못 받았나요?",
			resend2fa2: '다시 한 번 해 봐',
			authText: '인증 코드가 모바일/이메일로 전송되었습니다. 아래에 입력하십시오',
			cancel: '취소하다'
    	},

		setProfile: {
			label: '프로필 설정',
			firstName: '이름',
			lastName: '성'
		},
		
		setWallet: {
			label: '지갑 설정',
			import: '기존 지갑 가져오기',
			create: '새 지갑 만들기',

			importLabel: '기존 지갑 가져오기',
			yourPrivateKey: '당신의 사적인 열쇠',
			importButton: '수입하다',

			generateWallet: '지갑 생성',
			generateSuccess1: '지갑이 성공적으로 만들어졌습니다.',
			generateSuccess2: '몇 초 후에 대시보드로 리디렉션됩니다.',
		},

		dashboard: {
			history: '역사',
			changePin: '핀 변경',
			twofaVerification: '2FA 검증',
			getPrivateKey: '개인 키 가져오기',
			view: '보다',
			import: '수입품',
			generate: '발생시키다',

			rewardNode: "리워드 노드이다",
			unstake: "내기를 취소하다",

			logout: '로그아웃하다',

			mainWallet: '주 지갑',
			subWallet: '부지갑',
			myBlx: '나의 BLX',
			myTokens: '나의 증표들',
			myNfts: '내의 NFT들',
			tokensReceived: '받은 토큰',
			nftsReceived: '수신된 NFT 수',

			blxWallet: 'BLX 지갑',
			bloxStake: 'BLOX 말뚝',
			bloxdaoVote: 'BLOX DAO 투표',
			bloxBank: 'BLOX 은행',

			walletBalance: '지갑 잔고',
			comingSoon: '얼마 남지 않았습니다',
			stakeVotingPower: '의결권을 행사하다',

			recentTransactions: '최근의 거래',
			timestamp: '날짜와 시간',
			hash: '해시',
			input: '투입',
			output: '산출량',
			amount: '양',
			symbol: '기호',
			confirmations: '확인서',
			status: '상황',

			completed: '완료된',
			pending: '불완전한',

			// logout
			logoutLabel: '확실합니까?',
			ok: '네',
			cancel: '취소하다',

			// change pin
			changePinLabel: '핀 변경',
			currentPin: '전류 핀',
			newPin: '새 핀',
			confirmNewPin: '새 핀을 확인하다',
			showCurrentPin: '보여 주다',
    		hideCurrentPin: '숨다',
			showNew1Pin: '보여 주다',
    		hideNew1Pin: '숨다',
			showNew2Pin: '보여 주다',
    		hideNew2Pin: '숨다',

			// 2fa verification
			twofaVerificationLabel: '2FA 검증',
			mobileNumber: '휴대 전화 번호',
			email: '이메일',
			googleAuth: '구글 인증 프로그램',
			enterPin: '핀을 입력하여 확인하다',
			confirm: '확인하다',

			// view private key
			viewPrivateKeyLabel: '개인 키 보기',
			enterYourPin: '핀을 입력하다',
			submit: '제출하다',
			enterReceivedPin: '받은 일회용 핀 입력',
			resend2fa1: "2FA를 못 받았나요?",
			resend2fa2: '다시 한 번 해 봐',

			// get private key
			getPrivateKeyLabel: '개인 키를 받다',
			walletSeed: '지갑 종자',
			pleaseSave: '이 5개의 코드를 7개의 영숫자로 종이에 저장하세요(순서는 중요합니다). 이 씨앗을 사용하면 컴퓨터 고장 시 지갑을 복구할 수 있습니다.',
			warning: '경고:',
			warning1: '- 절대 당신의 씨앗을 공개하지 마세요.',
			warning2: '- 웹 사이트에 입력하지 마십시오.',
			warning3: '- 전자적으로 보관하지 마십시오.',
			warning4: '- 한 번만 액세스할 수 있습니다.',
			yourWalletGeneration: '지갑 생성 시드는',
			
			seed1: '씨앗 1',
			seed2: '씨앗 2',
			seed3: '씨앗 3',
			seed4: '씨앗 4',
			seed5: '씨앗 5',

			verify: '확인하다',
			copy: '알았다',
			close: '닫다',

			// import private key
			importPrivateKeyLabel: '개인 키 가져오기',
			importWarning1: '중요',
			importWarning2: ' - 새 키를 마이그레이트하기 전에 개인 키를 가지고 있어야 합니다.',
			enterPrivateKey: '개인 키 입력',
			importSuccessful: '수입에 성공하다.',

			// generate private key
			generateWalletAddressLabel: '지갑 주소 생성',
			generating: "지갑 생성...",
			generateSuccess: "새 지갑 주소를 생성했습니다.",

			confirmLabel: "확인",
			pleaseSaveConfirm: "개인 키와 5개의 시드 코드를 저장하십시오. 이 키를 잃어버리면 지갑이 없어집니다!",

			// asset cards
			viewDetails: "세부 정보 보기",
			send: "보내세요",

			next: "다음 분"
		},

		blxDashboard: {
			back: '돌아가',
			logout: '로그아웃하다',

			walletBalance: '지갑 잔고',

			coldStorage: '오프라인 저장소',
			send: '보내세요',
			receive: '받다',
			history: '역사',
			verumCoin: '베럼 동전',
			cryptoWallet: "암호화폐 지갑",

			recentTransactions: '최근의 거래',
			timestamp: '날짜와 시간',
			hash: '해시',
			input: '투입',
			output: '산출량',
			amount: '양',
			symbol: '기호',
			confirmations: '확인서',
			status: '상황',

			completed: '완료된',
			pending: '불완전한',

			// cold storage 
			coldStorageLabel: 'BLX를 수출하다/수입하다',
			balance: '잔고',
			export: '수출하다',
			import: '수입하다',
			fileSecretKey: '파일의 비밀 키',
			file: '파일',
			filePlaceholder: '파일을 선택하거나 여기에 놓으십시오...',
			dropPlaceholder: '파일을 여기에 놓으십시오...',
			browse: '둘러보다',
			successfulExport: '성공적으로 내보냈습니다.',
			mustRead: '가져오기: 반드시 읽어야 합니다!',
			importantMsg1: '제공된 파일 비밀 키는 동적으로 할당됩니다.',
			importantMsg2: '즉시 안전하게 저장하십시오.',
			importantMsg3: '지지 마!',
			alreadySaved: '예, 파일의 개인 키를 이미 저장했습니다.',

			// send
			sendLabel: 'BLX를 보내다',
			recipientAddress: '수취인의 지갑 주소',
			send: '보내세요',
			sendSuccess1: 'You successfully sent',
			sendSuccess2: 'BLX to',

			// deposit
			depositLabel: '보증금 BLX',
			depositMsg: 'QR 코드를 스캔하거나 이 BLX 주소로 코인을 보냅니다',
			copied: '알았다!',

			// verum coin
			verumCoinLabel: '베름코인에서 BLX를 회수하다',
			serialNo: '일련 번호',
			redeem: '상환',

			resend2fa1: "2FA를 못 받았나요?",
			resend2fa2: '다시 한 번 해 봐',

			chooseAsset: "자산 선택"
		},

		history: {
			stake: '말뚝',
			history: '역사',
			stakeHistory: '말뚝 박는',
			go: '가세요',
			chooseAsset: '자산을 선택하다',

			refId: '참조 ID',
			bloxStake: 'BLOX 말뚝',
			btcAmount: '비트코인 금액',
		},

		createAsset: {
			createTokenLabel: "토큰 자산 생성",
			createNftLabel: "NFT 자산 생성",
			sendBlx: "BLX 전송",
			send: "보내세요",
			cancel: "취소하다",

			important: "중요한",
			importantMsg1: "자산 계약 암호화 키(스마트 계약 해시 ID라고도 함)",
			importantMsg2: "아래 자산 키를 즉시 복사하여 보호하십시오",
			tapToCopy: "눌러서 복사", 
			keyIsCopied: "자산 키가 복사되었습니다",
			done: "다 했어요",

			uploadConfig: "구성 파일 업로드",
			or: "또는",
			assetName: "자산명",
			symbol: "기호",
			supply: "순환 공급",
			value: "BLX 값",
			description: "묘사",
			warning: "중요: 무단 액세스를 방지하기 위해 즉시 보안 및 저장! 개인 키를 분실하거나 공유하지 마십시오!",
			submit: "제출하다",

			assetNamePlaceholder: '토큰 자산 이름',
			symbolPlaceholder: '6자',
			supplyPlaceholder: '최대 한도 100억',
			descPlaceholder: '150자',

			icon: "아이콘",
			tokenGuidelines: "이미지를 업로드합니다. 최대 크기: 2MB. (.jpg, .png)",
			nftGuidelines: "이미지를 업로드합니다. 최대 크기: 10MB. (.jpg, .png, .mp3, .mp4)",

			enterAssetKeyLabel: "스마트 계약을 활성화하려면 자산 키를 입력하십시오",

			nowActivated: "이제 스마트 계약이 활성화되었습니다.",
			done: "다 했어요",

			// upload icon
			uploadIconLabel: '업로드 아이콘',
			uploadNftLabel: 'NFT 업로드',
			maximum: "최대 10MB",
			maximumToken: "최대 2MB",

			// upload config
			configLabel: "구성 파일 업로드",
			configFile: "구성 파일",
			filePlaceholder: '파일을 선택하거나 여기에 놓으십시오...',
			dropPlaceholder: '파일을 여기에 놓으십시오...',
			getSample: "여기서 샘플을 구하세요",
			upload: "업로드",
		},

		assetDashboard: {
			back: '돌아가',
			logout: '로그아웃하다',
			
			balance: "잔고",
			assetName: "자산명",
			assetSymbol: "자산 기호",
			totalSupply: "총공급량",
			walletAddress: "지갑 주소",
			description: "묘사",

			recentTransactions: '최근의 거래',
			timestamp: '날짜와 시간',
			hash: '해시',
			input: '투입',
			output: '산출량',
			amount: '양',
			symbol: '기호',
			confirmations: '확인서',
			status: '상황',

			// send subasset
			sendLabel: '보내세요',
			yourPrivateKey: '개인 키'
		},

		cryptoWallet: {
			chooseCryptoLabel: "암호화폐 선택",
			deposit: "보증금",
			exchange: "교환하다",
			withdrawLabel: "철회",
			withdraw: "철회",

			receive: "받다",
			balance: "균형",
			scanQr1: "QR 코드를 스캔하거나 이",
			scanQr2: "주소로 코인을 보냅니다:",
			copy: "알았다",
			copied: "복사한",

			next: "다음 분",
			tokenOnly1: "이 주소는",
			tokenOnly2: "전용입니다!",
			tokenOnlyDesc: "다른 통화를 보내지 마세요! 다른 크립토 보내시면 받으시거나 돌려드릴 수 없습니다.",
			amount: "양",
			hashId: "트랜잭션 해시 ID:",
			submit: "제출하다",
			cancel: "취소하다",
			depositSuccess: "트랜잭션이 진행 중입니다. 입금이 확인되면 당신의 계좌가 입금될 것입니다.",
			close: "가까운",

			enterAmountOf: "금액입력",
			amountOf: "금액",
			exchangeBtn: "교환하다",
			exchangeSuccessful: "트랜잭션이 성공했습니다.",
			tokens: "토큰:",
			amountIn: "금액",
			price: "가격:",
			per: "각각에 대하여",
			done: "다 했어요",
		},

		chat: {
			hi: '안녕하세요,',
			instructions: '아래에 그들의 지갑 주소를 붙여 메시지를 보내라.',
			enterWalletAddress: '지갑주소를입력하세요',
			startChat: '잡담을 시작하다',
			contactsList: '연락처 목록',
			newMessage: '새 메시지',
			noContact: '연락처 없음',
			exitChat: '채팅 종료',
			writeMsg: '메시지 작성'
		},
		
		rewardNode: {
			label: "리워드 노드가 되십시오",
			balance: "균형",
			proof: "지분 증명 필요",
			ok: "네",
			cancel: "취소하다",
			important: "중요한",
			keySubtext1: "자산 계약 암호화 키(AKA 스마트 계약 해시 ID)입니다",
			keySubtext2: "아래 자산 키를 즉시 복사하여 보호하십시오",
			tapToCopy: "눌러서 복사",
			isCopied: "자산 키가 복사되었습니다",
			download: "다운로드",
			instructions1: "다운로드한 파일을 실행합니다.JAR 파일입니다, Java 8을 다운로드한 후 터미널에서 다음 명령을 실행해야 합니다.",
			done: "다 했어요",

			//unstake
			unstakeLabel: "이해되지 않다",
			assetKey: "자산 키",
			submit: "제출하다",
			unstakeSuccessful: "성공적으로 분리됨",
			done: "다 했어요"
		},
    }
};


const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;